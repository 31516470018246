<template>
  <div
    :class="classContainer"
    class="rounded-xl"
  >
    <v-container
      v-if="!isFilterLoading"
      class="base-content pa-4"
    >
      <v-row dense>
        <v-radio-group
          v-model="districtType"
          class="mr-auto"
          dense
          row
          hide-details
        >
          <v-radio
            label="Народный"
            value="custom"
          ></v-radio>
          <v-radio
            label="Официальный"
            value="official"
          ></v-radio>
        </v-radio-group>
        <v-text-field
          v-model="filterLocalValues.advertId"
          placeholder="поиск по ID"
          class="ml-auto align-center"
          style="max-width: 23.3%"
          hide-details
          outlined
          dense
        />
      </v-row>

      <v-row class="d-flex align-center pt-md-4">
        <v-col
          class="pt-4"
          :cols="$vuetify.breakpoint.xs ? 12 : 3"
          sm="6"
          md="3"
        >
          <DistrictFilter
            :district-custom.sync="filterLocalValues.districtCustom"
            :district-official.sync="filterLocalValues.districtOfficial"
            @changeDistrict="districtType = $event"
            :districtType="districtType"
            class="pb-sm-3"
          />
        </v-col>
        <v-col
          class="pt-0 pt-4"
          :cols="$vuetify.breakpoint.xs ? 12 : 3"
          md="3"
          sm="6"
        >
          <MultipleSelection
            v-model="filterLocalValues.flatType"
            :items="flatTypeList"
            label="Тип жилья"
            class="pb-sm-3"
          />
        </v-col>
        <v-col
          class="pt-0 pb-2 padding bottom"
          :cols="$vuetify.breakpoint.xs ? 12 : 3"
          md="3"
          sm="6"
        >
          <Range
            v-model="rooms"
            :title="`Количество комнат`"
            :range="filterRanges.rooms"
            :cols="$vuetify.breakpoint.smallXS ? 12 : 6"
          />
        </v-col>
        <v-col
          class="pt-0 pb-2 padding"
          :cols="$vuetify.breakpoint.xs ? 12 : 3"
          md="3"
          sm="6"
        >
          <Range
            v-model="price"
            :title="`Цена`"
            :type="'text'"
            :range="filterRanges.price"
          />
        </v-col>
      </v-row>
      <div class="more-filter pt-1">
        <v-row class="d-flex align-center pb-4 pt-sm-4">
          <!--        <v-col-->
          <!--          v-if="isLogged"-->
          <!--          class="pt-0 pb-0"-->
          <!--          :cols="$vuetify.breakpoint.xs ? 12 : 3"-->
          <!--          md="3"-->
          <!--          sm="6"-->
          <!--        >-->
          <!--          <MultipleSelection-->
          <!--            v-model="filterLocalValues.subdistrict"-->
          <!--            :items="subdistrictItems"-->
          <!--            label="Подрайон"-->
          <!--          />-->
          <!--        </v-col>-->
          <v-col
            class="pt-0 pb-3 pt-4 padding remove-padding"
            :cols="$vuetify.breakpoint.xs ? 12 : 3"
            md="3"
            sm="6"
          >
            <Select
              v-model="buildingMaterial"
              :items="buildingMaterialItems"
              label="Материал стен"
              dense
              solo
              outlined
              hide-details
              class="pb-3"
            />
          </v-col>
          <v-col
            class="pt-0 pb-3 pt-4 padding remove-padding bottom"
            :cols="$vuetify.breakpoint.xs ? 12 : 3"
            md="3"
            sm="6"
          >
            <MultipleSelection
              v-model="filterLocalValues.flatStatus"
              :items="flatStatusItemsWithEmpty"
              label="Состояние квартиры"
              class="pb-3"
            />
          </v-col>
          <v-col
            class="pt-0 padding"
            :cols="$vuetify.breakpoint.xs ? 12 : 3"
            md="3"
            sm="6"
          >
            <Range
              v-model="floors"
              :title="`Этажность дома`"
              :range="filterRanges.floors"
            />
          </v-col>
          <v-col
            class="pt-0 padding"
            :cols="$vuetify.breakpoint.xs ? 12 : 3"
            md="3"
            sm="6"
          >
            <Range
              v-model="floor"
              :title="`Выбрать этаж`"
              :range="filterRanges.floor"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="pt-0"
            :cols="$vuetify.breakpoint.xs ? 12 : 3"
            md="3"
            sm="6"
          >
            <v-select
              v-model="filterLocalValues.kitchenType"
              :items="planningList"
              label="Планировка"
              item-value="value"
              item-text="value"
              return-object
              multiple
              dense
              solo
              hide-details
              outlined
              class="pb-sm-3"
              :disabled="!(filterLocalValues.rooms[0] || filterLocalValues.rooms[1])"
            ></v-select>
          </v-col>
          <v-col
            class="pt-0"
            :cols="$vuetify.breakpoint.xs ? 12 : 3"
            md="3"
            sm="6"
          >
            <span></span>
          </v-col>
          <v-col
            class="pt-0 pb-0"
            :cols="$vuetify.breakpoint.xs ? 12 : 3"
            md="3"
            sm="6"
          >
            <Range
              v-model="buildingYear"
              :title="`Год постройки`"
              :range="filterRanges.buildingYear"
            />
          </v-col>
          <v-col
            class="pt-0 pb-0 square-padding"
            :cols="$vuetify.breakpoint.xs ? 12 : 3"
            md="3"
            sm="6"
          >
            <Range
              v-model="areaCommon"
              :title="`Общая площадь`"
              :range="filterRanges.areaCommon"
            />
          </v-col>
          <!--        <v-col-->
          <!--          v-if="isLogged"-->
          <!--          class="pt-0"-->
          <!--        >-->
          <!--          <AddressFilter-->
          <!--            :street-id.sync="streetId"-->
          <!--            :building-number-id.sync="buildingNumberId"-->
          <!--          />-->
          <!--        </v-col>-->
        </v-row>
      </div>

      <div class="toggle-filter d-flex justify-space-between">
        <div>
          <Btn
            color="primary"
            text
            :title="advancedSetting ? (widthWindow > 1350 ? 'скрыть дополнительные параметры ': 'Скрыть параметры') : 'еще параметры'"
            :icon="advancedSetting ? 'mdi-close' : 'mdi-tune'"
            @click="advancedSetting = !advancedSetting"
            class="ml-auto"
            positionIcon="right"
          />
        </div>
        <div class="d-flex flex-row filter-btns">
          <Btn
            outlined
            rounded
            class="mr-1"
            :title="widthWindow > 800 ? 'Очистить фильтр и показать все квартиры': 'Очистить фильтр'"
            v-if="isFilterActive()"
            @click="handleClear"
            :maxWidth="widthWindow < 800 ? '180' : null"
          />
          <Btn
            rounded
            class="colorful-button white--text pa-5"
            title="Найти квартиру"
            :loading="isLoading"
            @click="handleFind"
            max-width="180"
          />
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import Range from './Filters/Range'
import { cleanClone, deepEqual } from '@/components/utils/common'
import MultipleSelection from '@/components/views/Sale/Filters/MultipleSelection'
import serviceDataComputed from '@/components/mixins/serviceDataComputed'
import formatters from '@/components/mixins/formatters'
import isLogged from '@/components/mixins/isLogged'
import AddressFilter from '@/components/views/Sale/Filters/AddressFilter'
import DistrictFilter from '@/components/views/Sale/Filters/DistrictFilter'
import Btn from '@/components/base/Btn'
import Select from '@/components/base/Select'
import windowSize from '@/components/mixins/windowSize'
import planningTypesByRooms from '@/components/views/Sale/Filters/planningTypesByRooms'

export default {
  mixins: [serviceDataComputed, formatters, isLogged, windowSize, planningTypesByRooms],
  name: 'Filters',
  components: { DistrictFilter, AddressFilter, MultipleSelection, Range, Btn, Select },
  props: {
    value: Object,
    isLoading: Boolean,
    fullWidth: Boolean
  },
  data () {
    return {
      districtType: 'official',
      isFilterLoading: false,
      advancedSetting: false,
      filterRanges: {
        rooms: [0, 0],
        areaCommon: [0, 0],
        floor: [0, 0],
        floors: [0, 0],
        price: [0, 0],
        buildingYear: [0, 0]
      },
      filterLocalValues: {
        advertId: null,
        rooms: [],
        floor: [],
        floors: [],
        price: [],
        areaCommon: [],
        buildingYear: [],
        districtCustom: null,
        districtOfficial: null,
        buildingNumberId: null,
        streetId: null,
        flatType: [],
        kitchenType: [],
        buildingMaterial: null,
        flatStatus: [],
        builder: []
      },
      radios: 'custom'
    }
  },
  computed: {
    classContainer () {
      let result = this.advancedSetting ? 'is-open' : ''
      if (this.fullWidth) {
        result += ' fullWidth'
      }
      return result
    },
    price: {
      get () {
        return this.filterLocalValues.price.map(item => this.formatPriceInput(item * 1000))
      },
      set (value) {
        this.$set(this.filterLocalValues, 'price', value.map(item => this.reformatPrice(item) / 1000))
      }
    },
    buildingYear: {
      get () {
        return this.filterLocalValues.buildingYear
      },
      set (value) {
        this.filterLocalValues.buildingYear = value
      }
    },
    buildingMaterial: {
      get () {
        return this.filterLocalValues.buildingMaterial * 1
      },
      set (value) {
        this.filterLocalValues.buildingMaterial = value
      }
    },
    floors: {
      get () {
        return this.filterLocalValues.floors
      },
      set (value) {
        this.filterLocalValues.floors = value
      }
    },
    floor: {
      get () {
        return this.filterLocalValues.floor
      },
      set (value) {
        this.filterLocalValues.floor = value
      }
    },
    areaCommon: {
      get () {
        return this.filterLocalValues.areaCommon
      },
      set (value) {
        this.filterLocalValues.areaCommon = value
      }
    },
    rooms: {
      get () {
        return this.filterLocalValues.rooms
      },
      set (value) {
        this.filterLocalValues.rooms = value
      }
    },
    streetId: {
      get () {
        return this.filterLocalValues.streetId * 1
      },
      set (val) {
        this.filterLocalValues.streetId = val
      }
    },
    buildingNumberId: {
      get () {
        return this.filterLocalValues.buildingNumberId * 1
      },
      set (val) {
        this.filterLocalValues.buildingNumberId = val
      }
    }
  },
  watch: {
    value: {
      handler (newVal, oldVal) {
        if (!deepEqual(newVal, oldVal)) {
          if (!Object.entries(this.value).length) {
            this.handleClear()
          } else {
            this.loadFilters()
          }
        }
      },
      deep: true
    }
  },
  methods: {
    async fillFilterDefaults () {
      this.isFilterLoading = true
      const response = await this.$store.dispatch('server/get', { url: '/sale/filter-defaults' })
      if (response) {
        this.filterRanges = cleanClone(response)
      }
      this.isFilterLoading = false
      return true
    },
    loadFilters () {
      if (Object.entries(this.value).length) {
        const filters = cleanClone(this.value)
        for (const prop in filters) {
          if (filters.hasOwnProperty(prop) && this.filterLocalValues.hasOwnProperty(prop)) {
            if (Array.isArray(filters[prop])) {
              filters[prop] = filters[prop].map(item => item * 1)
            }
            this.filterLocalValues[prop] = filters[prop]
          }
        }
      }
    },
    isFilterActive () {
      let isActive = false
      for (let filterName in this.filterLocalValues) {
        if (this.filterLocalValues.hasOwnProperty(filterName) && filterName !== 'phoneFilter' && filterName !== 'advertId' && filterName !== 'isVerified' && filterName !== 'advStatus') {
          if (Array.isArray(this.filterLocalValues[filterName])) {
            if (this.filterLocalValues[filterName].find(item => item !== null)) {
              isActive = true
              break
            }
          } else {
            if (this.filterLocalValues[filterName]) {
              if (typeof this.filterLocalValues[filterName] === 'object') {
                if (Object.keys(this.filterLocalValues[filterName]).length) {
                  isActive = true
                  break
                }
              } else {
                isActive = true
                break
              }
            }
          }
        }
      }
      return isActive
    },
    handleFind () {
      this.$emit('input', cleanClone(this.filterLocalValues))
      if (this.$route.name === 'sale') {
        this.$vuetify.goTo('#AdvertItems', { duration: 500, offset: 70, easing: 'easeInOutCubic' })
      }
    },
    handleClear () {
      for (const filter in this.filterLocalValues) {
        if (this.filterLocalValues.hasOwnProperty(filter)) {
          if (Array.isArray(this.filterLocalValues[filter])) {
            this.filterLocalValues[filter] = []
          } else if (typeof this.filterLocalValues[filter] === 'object') {
            this.filterLocalValues[filter] = {}
          } else {
            this.filterLocalValues[filter] = null
          }
        }
      }
      this.$emit('input', cleanClone(this.filterLocalValues))
    }
  },
  async created () {
    this.loadFilters()
    return this.fillFilterDefaults()
  }
}
</script>

<style scoped>
  /deep/ .v-input--radio-group__input {
    flex-wrap: nowrap !important;
  }
  .fullWidth {
    width: 95%;
    margin: 0 auto;
    margin-top: 24px;
  }
  .padding {
    padding-top: 14px !important;
  }
  .v-select__selections {
    max-height: 38px;
    flex-wrap: nowrap;
  }
  .v-application--is-ltr .v-messages {
    white-space: nowrap;
  }
  .more-filter {
    overflow: hidden;
    height: 0;
    transition: all .3s ease-in;
  }
  .toggle-filter {
    position: relative;
    margin-bottom: 10px;
  }
  .is-open .more-filter {
    height: 250px;
  }
  @media (max-width: 1000px) {
    .remove-padding {
      padding-top: 0 !important;
    }
    .fullWidth {
      width: 100%;
      margin-top: 0
    }
  }
  @media (max-width: 600px) {
    .square-padding {
      padding-top: 14px !important;
    }
    .bottom {
      padding-bottom: 0 !important;
    }
  }
  @media (max-width: 800px) and (min-width: 200px) {
    .is-open .more-filter {
      height: auto;
      padding-top: 0;
    }
    .toggle-filter {
      flex-direction: column;
      gap: 10px;
      justify-content: center !important;
      align-items: center;
    }
    .filter-btns {
      flex-direction: column !important;
      gap: 15px;
    }
  }
</style>

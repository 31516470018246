<template>
  <v-card
    elevation="0"
    class="pa-3 pt-6"
  >
    <h2 class="text-uppercase pb-6">Работаем на качество</h2>
    <v-img
      :src="logo"
    />
    <div class="blocks pt-6">
      <v-card
        rounded
        elevation="0"
        class="pa-3 block rounded-lg pt-8 pb-6"
        v-for="(item, index) in info"
        :key="index"
      >
        {{ item }}
      </v-card>
    </div>
  </v-card>
</template>

<script>
/*import womanFull from '@/assets/woman-full.png'*/
import logo from '@/assets/logo800x325.jpeg'

export default {
  name: 'QualityBlock',
  data () {
    return {
      // womanImage: womanFull,
      info: [
        'Мы не подписываем с нашими клиентами никакие показники, поскольку у нас с ними изначально доверительные отношения',
        'Мы являемся официальными партнерами всех банков, работающих в Ростове-на-Дону. Мы оказываем помощь в одобрении ипотеки. Для наших клиентов это бесплатно',
        'Информация о каждом многоквартирном доме на нашем сайте проверена. Все фотографии домов, которые находится на нашем сайте сделаны нами лично',
        'Мы являемся официальными партнерами 90% застройщиков по всей России. Квартиры в новостройках у нас продаются без комиссии агентству.'
      ],
      logo
    }
  }
}
</script>

<style scoped>
  .blocks {
    display: grid;
    grid-template-columns: 4fr 3.3fr;
    gap: 10px;
    padding: 10px;
  }
  .block {
    border: 1px solid #515151;
  }
  @media (max-width: 600px) {
    .blocks {
      display: flex;
      flex-direction: column;
    }
  }
</style>
